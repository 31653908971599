import React from "react";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";

import MusicCategoryDisplay from "./Categories/Music/MusicCategoryDisplay";
import ProgrammingCategoryDisplay from "./Categories/Programming/ProgrammingCategoryDisplay";
import MathsCategoryDisplay from "./Categories/Maths/MathsCategoryDisplay";

import "./App.scss";

function App() {
	return (
		<>
			<Navbar fixed="top" expand="lg" bg="dark">
				<Container>
					<Navbar.Brand href="#home">Nathan Douglas</Navbar.Brand>
				</Container>
			</Navbar>
			<div className="main-display d-flex flex-column justify-content-center">
				<Container className="inner-display">
					<h1 className="display-1 fw-bold">Nathan Douglas</h1>
					<div className="col-md-8">
						<p className="fs-4 lead">
							Hi, I'm a Cambridge Mathematics graduate who makes random projects from time to time
							depending on what has caught my interest (often something to do with Maths, Programming, or
							Music).
						</p>
					</div>
				</Container>
			</div>
			<div className="categories">
				<MathsCategoryDisplay />
				<ProgrammingCategoryDisplay />
				<MusicCategoryDisplay />
			</div>
		</>
	);
}

export default App;
