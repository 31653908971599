import Card from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup";
import musescoreLogo from "./musescore_logo_square.png";

import CategoryDisplay from "../CategoryDisplay";

function MusicCategoryDisplay() {
	return (
		<CategoryDisplay
			categoryName="Music"
			categoryLeadParagraph="In my free time I play acoustic guitar and sometimes try to make arrangements for songs that I've heard. Below are some of the projects I've worked on:"
		>
			<Card>
				<Card.Img variant="top" src={musescoreLogo} />
				<Card.Body>
					<Card.Title>Arrangements</Card.Title>
					<Card.Text>Here are some of the arrangements I've created, made using Musescore</Card.Text>
				</Card.Body>
				<ListGroup className="list-group-flush">
					<ListGroup.Item>
						<Card.Link href="https://musescore.com/user/2983961/scores/2393386">
							Inti Illimani - Huajra
						</Card.Link>
					</ListGroup.Item>
					<ListGroup.Item>
						<Card.Link href="https://musescore.com/user/2983961/scores/20519689">
							Outer Wilds - Main Theme
						</Card.Link>
					</ListGroup.Item>
				</ListGroup>
				<Card.Body>
					<Card.Link href="https://musescore.com/user/2983961/sets/12908134">All Arrangements</Card.Link>
				</Card.Body>
			</Card>
			<Card>
				<Card.Img variant="top" src={musescoreLogo} />
				<Card.Body>
					<Card.Title>Compositions</Card.Title>
					<Card.Text>Here are some of the compositions I've created, made using Musescore</Card.Text>
				</Card.Body>
				<ListGroup className="list-group-flush">
					<ListGroup.Item>
						<Card.Link href="https://musescore.com/user/2983961/scores/22166641">Moment</Card.Link>
					</ListGroup.Item>
				</ListGroup>
				<Card.Body>
					<Card.Link href="https://musescore.com/user/2983961/sets/12908140">All Compositions</Card.Link>
				</Card.Body>
			</Card>
		</CategoryDisplay>
	);
}

export default MusicCategoryDisplay;
