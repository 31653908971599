import CategoryDisplay from "../CategoryDisplay";

function MathsCategoryDisplay() {
	return (
		<CategoryDisplay
			categoryName="Mathematics"
			categoryLeadParagraph="During my time at Cambridge, I studied a variety areas within Maths, taking particular interest in Statistics, Probability and Machine Learning. Below are some projects for things that I've looked into."
		></CategoryDisplay>
	);
}

export default MathsCategoryDisplay;
