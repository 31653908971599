import CategoryDisplay from "../CategoryDisplay";

function ProgrammingCategoryDisplay() {
	return (
		<CategoryDisplay
			categoryName="Programming"
			categoryLeadParagraph="I got into programming some time during high school and since then have worked on some projects here and there. Below is a selection of them:"
		></CategoryDisplay>
	);
}

export default ProgrammingCategoryDisplay;
