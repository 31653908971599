import React from "react";
import Container from "react-bootstrap/Container";
import Carousel from "react-bootstrap/Carousel";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import "./CategoryDisplay.scss";

interface ICategoryDisplayProps {
	categoryName: string;
	categoryLeadParagraph: string;
	children?: string | React.ReactNode | React.ReactNode[];
}

function CategoryDisplay(CategoryDisplayProps: ICategoryDisplayProps) {
	const { categoryName, categoryLeadParagraph } = CategoryDisplayProps;
	const children = CategoryDisplayProps.children ?? [];

	const smallCarouselList: React.ReactElement[] = [];
	const mediumCarouselList: React.ReactElement[] = [];
	const largeCarouselList: React.ReactElement[] = [];

	const cardList = Array.isArray(children) ? children : [children];

	let tempMedRow: React.ReactElement[] = [];
	let tempLargeRow: React.ReactElement[] = [];

	for (let i = 0; i < cardList.length; i++) {
		const card = cardList[i];
		const colCard = <Col>{card}</Col>;

		// Handle Small Row
		smallCarouselList.push(
			<Carousel.Item>
				<Container>
					<Row sm={1}>{colCard}</Row>
				</Container>
			</Carousel.Item>
		);

		// Handle Medium Row
		tempMedRow.push(colCard);
		if (tempMedRow.length === 2 || i === cardList.length - 1) {
			mediumCarouselList.push(
				<Carousel.Item>
					<Container>
						<Row md={2}>{tempMedRow}</Row>
					</Container>
				</Carousel.Item>
			);
			tempMedRow = [];
		}

		// Handle Large Row
		tempLargeRow.push(colCard);
		if (tempLargeRow.length === 3 || i === cardList.length - 1) {
			largeCarouselList.push(
				<Carousel.Item>
					<Container>
						<Row lg={3}>{tempLargeRow}</Row>
					</Container>
				</Carousel.Item>
			);
			tempLargeRow = [];
		}
	}

	const smallCarousel = (
		<Carousel indicators={false} interval={null} data-bs-theme="dark" className="topic-carousel d-md-none">
			{smallCarouselList}
		</Carousel>
	);
	const mediumCarousel = (
		<Carousel
			indicators={false}
			interval={null}
			data-bs-theme="dark"
			className="topic-carousel d-none d-md-block d-xxl-none"
		>
			{mediumCarouselList}
		</Carousel>
	);
	const largeCarousel = (
		<Carousel indicators={false} interval={null} data-bs-theme="dark" className="topic-carousel d-none d-xxl-block">
			{largeCarouselList}
		</Carousel>
	);

	return (
		<div className="category-display">
			<Container className="inner-display">
				<h1 className="display-1 fw-bold">{categoryName}</h1>
				<div className="lead-paragraph col-md-8">
					<p className="fs-4 lead">{categoryLeadParagraph}</p>
				</div>

				{cardList.length > 0 ? smallCarousel : null}
				{cardList.length > 0 ? mediumCarousel : null}
				{cardList.length > 0 ? largeCarousel : null}
			</Container>
		</div>
	);
}

export default CategoryDisplay;
